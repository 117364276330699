import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect, useRef } from 'react';
import { Text, View, StyleSheet, ScrollView, RefreshControl, Alert, Linking } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Button, Divider, Avatar, Appbar, Badge, Card, IconButton, DefaultTheme } from 'react-native-paper';
import { getMatch } from '../../app/store/actions/userActions';
import { connectWebSocket } from '../../app/store/actions/websocketActions';
import AppBar from '../../AppBar';
import globalStyles from '../../app/components/Styles';
import moment from 'moment';
import { followMatch } from '../../app/store/actions/userActions';
import { TabScreen, Tabs, TabsProvider } from 'react-native-paper-tabs';
import MatchEventCard from '../../app/components/MatchEventCard';

export const Match = ({ user, getMatch, isLoading, match, events, connectWebSocket, route, followMatch, squad }) => {

    const { matchId } = route.params;
    const role = user?.type
    const navigation = useNavigation();
    const [matchDate, setMatchDate] = React.useState(moment(match?.date).add(-3, "hours"))
    const [time, setTime] = React.useState(moment(moment() - moment(match?.startedAt)).minutes());
    const [live, setLive] = React.useState(moment().isAfter(matchDate) && match?.started && !match?.ended)
    const [action, setAction] = useState(false)

    const refresher = async () => {
        const device = await Device.getDeviceTypeAsync()
        if (device !== Device.DeviceType.DESKTOP) {
            connectWebSocket()
        }
        getMatch({ matchId })
        setMatchDate(moment(match?.date).add(-3, "hours"))
        setLive(moment().isAfter(matchDate) && match?.started && !match?.ended)
    }

    useEffect(() => {
        if (!isLoading) {
            refresher()
        } else {
            const interval = setInterval(() => {
                setTime(moment(moment() - moment(match?.date)).minutes())
                setLive(moment().isAfter(matchDate) && !match?.ended)
            }, 100);
            return () => {
                clearInterval(interval);
            };
        }
    }, [user, matchId])

    useEffect(() => {
        getMatch({ matchId })
    }, [match?.scoreA, match?.scoreB, action])

    if (isLoading) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    const handleIcon = (type) => {
        switch (type) {
            case "Goal": return <Avatar.Icon style={{ backgroundColor: "white", marginHorizontal: 5 }} size={30} color='green' icon="soccer" />
            case "Assist": return <Avatar.Icon style={{ backgroundColor: "white", marginHorizontal: 5 }} size={30} color='black' icon="shoe-cleat" />
            case "Own Goal": return <Avatar.Icon style={{ backgroundColor: "white", marginHorizontal: 5 }} size={30} color='red' icon="soccer" />
            case "Yellow Card": return <Avatar.Icon style={{ backgroundColor: "white", marginHorizontal: 5 }} size={30} color='yellow' icon="cards" />
            case "Second Yellow Card": return <Avatar.Icon style={{ backgroundColor: "white", marginHorizontal: 5 }} size={30} color='red' icon="cards" />
            case "Red Card": return <Avatar.Icon style={{ backgroundColor: "white", marginHorizontal: 5 }} size={30} color='red' icon="cards" />
        }
    }

    return (
        <>
            <AppBar navigation={navigation} title={`${match?.teamA?.name} - ${match?.teamB?.name}`} action={<>
                {match?.livestream && <Appbar.Action icon="youtube-tv" iconColor='red' onPress={() => Linking.openURL(match?.livestream)} />}
                {role === "Super" && <Appbar.Action icon="pencil" onPress={() => navigation.navigate("EditMatch", { match, tournament: match.tournament })} />}
                {role === "User" && (
                    user.matchesFollowed.includes(match?._id) ?
                        <Appbar.Action icon="star" iconColor='gold' /> :
                        <Appbar.Action onPress={() => followMatch({ match: match?._id })} icon="star-outline" />
                )}
            </>} />
            <TabsProvider defaultIndex={0}>
                <Tabs mode="scrollable" showLeadingSpace={false} theme={{
                    ...DefaultTheme,
                    colors: {
                        ...DefaultTheme.colors,
                        surface: '#000B26',
                        onSurfaceVariant: "#ffffff",
                        onSurface: '#ffffff',
                    },
                }}>
                    <TabScreen label="Events">
                        <ScrollView refreshControl={<RefreshControl onRefresh={refresher} />}>
                            <View style={globalStyles.container}>
                                <View style={{ width: "100%", ...grid.container }}>
                                    <View style={{
                                        flex: 1,
                                        alignItems: 'center',
                                        justifyContent: "flex-start",
                                        flexDirection: "column",
                                    }}>
                                        <Text style={grid.textStyle}>{match?.teamA?.name.slice(0, 3).toUpperCase()}</Text>
                                        {match?.teamAColor && <Avatar.Icon style={{ backgroundColor: "transparent", marginHorizontal: 5 }} size={30} color={match?.teamAColor} icon="tshirt-crew" />}
                                    </View>
                                    <View style={grid.centerColumn}>
                                        <Text style={{ ...grid.textStyle, color: live ? "green" : "", textAlign: "center" }}>{!match?.started ? `${moment(matchDate).format("hh:mm a")}` : `${match?.scoreA}-${match?.scoreB}`}</Text>
                                    </View>
                                    <View style={{
                                        flex: 1,
                                        alignItems: 'center',
                                        justifyContent: "flex-end",
                                        flexDirection: "column",
                                    }}>
                                        <Text style={grid.textStyle}>{match?.teamB?.name.slice(0, 3).toUpperCase()}</Text>
                                        {match?.teamBColor && <Avatar.Icon style={{ backgroundColor: "transparent", marginHorizontal: 5 }} size={30} color={match?.teamBColor} icon="tshirt-crew" />}
                                    </View>
                                </View>
                                {
                                    (role === "Super" || role === "Admin") && (
                                        <View>
                                            <Divider />
                                            <Button style={{ marginTop: 8 }} mode="contained" onPress={() => navigation.navigate('UpdateMatch', { match })}>
                                                Add Update
                                            </Button>
                                        </View>
                                    )
                                } 
                                <Divider />
                                <View>
                                    {events?.map((event, idx) => {
                                        return (!role || role === "User") ? (
                                            <View key={idx} style={grid.container}>
                                                <View style={grid.leftColumn}>
                                                    {event.team._id === match.teamA._id && <>{handleIcon(event.type === "Caution" ? event.cautionType : event.type)}<Text>{event.player ? event.player.name : "<TBD>"}</Text></>}
                                                </View>
                                                <View style={grid.centerColumn}>
                                                    <Text>{event.time}'</Text>
                                                </View>
                                                <View style={grid.rightColumn}>
                                                    {event.team._id === match.teamB._id && <><Text>{event.player ? event.player.name : "<TBD>"}</Text>{handleIcon(event.type === "Caution" ? event.cautionType : event.type)}</>}
                                                </View>
                                            </View>
                                        ) : (
                                            <View style={grid.container}>
                                                <MatchEventCard key={idx} event={event} match={match} navigation={navigation} action={action} setAction={setAction} />
                                            </View>
                                        )
                                    })}
                                </View>
                            </View>
                        </ScrollView>
                    </TabScreen>
                    <TabScreen label="Lineup">
                        <ScrollView refreshControl={<RefreshControl onRefresh={refresher} />}>
                            <View style={{ ...globalStyles.container, margin: 15 }}>
                                <View style={{ flexDirection: "column" }}>
                                    <Text style={{ fontSize: 25, fontWeight: "bold", marginBottom: 2 }}>{match?.teamA.name}</Text>
                                    <Divider />
                                    {squad?.teamA?.map((player, idx) => {
                                        return (
                                            <View key={idx} style={{ ...grid.container }}>
                                                <View style={grid.leftColumn}>
                                                    <Badge style={{ backgroundColor: match?.teamA.color || "blue", marginRight: 10 }}>{player.player.number}</Badge><Text>{player.player.name}</Text>
                                                </View>
                                                <View style={{ ...grid.rightColumn, flexDirection: "row" }}>
                                                    {player.goalCount > 0 && <View style={grid.item}>
                                                        <Avatar.Icon style={{ backgroundColor: "white", marginHorizontal: 5 }} icon="soccer" size={35} />
                                                        <Badge
                                                            style={{ ...grid.badge, backgroundColor: "green" }}
                                                        >
                                                            {player.goalCount}
                                                        </Badge>
                                                    </View>}
                                                    {player.assistCount > 0 && <View style={grid.item}>
                                                        <Avatar.Icon style={{ backgroundColor: "white", marginHorizontal: 5 }} icon="shoe-cleat" size={35} />
                                                        <Badge
                                                            style={{ ...grid.badge, backgroundColor: "green" }}
                                                        >
                                                            {player.assistCount}
                                                        </Badge>
                                                    </View>}
                                                    {player.yellowCount > 0 && <View style={grid.item}>
                                                        <Avatar.Icon style={{ backgroundColor: "white", marginHorizontal: 5 }} color="yellow" icon="cards" size={35} />
                                                        <Badge
                                                            style={{ ...grid.badge, backgroundColor: "green" }}
                                                        >
                                                            {player.yellowCount}
                                                        </Badge>
                                                    </View>}
                                                    {player.redCount > 0 && <View style={grid.item}>
                                                        <Avatar.Icon style={{ backgroundColor: "white", marginHorizontal: 5 }} color="red" icon="cards" size={35} />
                                                        <Badge
                                                            style={{ ...grid.badge, backgroundColor: "green" }}
                                                        >
                                                            {player.redCount}
                                                        </Badge>
                                                    </View>}
                                                </View>
                                            </View>
                                        )
                                    })}
                                </View>

                                <View style={{ flexDirection: "column" }}>
                                    <Text style={{ fontSize: 25, fontWeight: "bold", marginBottom: 2 }}>{match?.teamB.name}</Text>
                                    <Divider />
                                    {squad?.teamB?.map((player, idx) => {
                                        return (
                                            <View key={idx} style={{ ...grid.container }}>
                                                <View style={grid.leftColumn}>
                                                    <Badge style={{ backgroundColor: match?.teamB.color || "red", marginRight: 10 }}>{player.player.number}</Badge><Text>{player.player.name}</Text>
                                                </View>
                                                <View style={{ ...grid.rightColumn, flexDirection: "row" }}>
                                                    {player.goalCount > 0 && <View style={grid.item}>
                                                        <Avatar.Icon style={{ backgroundColor: "white", marginHorizontal: 5 }} icon="soccer" size={35} />
                                                        <Badge
                                                            style={{ ...grid.badge, backgroundColor: "green" }}
                                                        >
                                                            {player.goalCount}
                                                        </Badge>
                                                    </View>}
                                                    {player.assistCount > 0 && <View style={grid.item}>
                                                        <Avatar.Icon style={{ backgroundColor: "white", marginHorizontal: 5 }} icon="shoe-cleat" size={35} />
                                                        <Badge
                                                            style={{ ...grid.badge, backgroundColor: "green" }}
                                                        >
                                                            {player.assistCount}
                                                        </Badge>
                                                    </View>}
                                                    {player.yellowCount > 0 && <View style={grid.item}>
                                                        <Avatar.Icon style={{ backgroundColor: "white", marginHorizontal: 5 }} color="yellow" icon="cards" size={35} />
                                                        <Badge
                                                            style={{ ...grid.badge, backgroundColor: "green" }}
                                                        >
                                                            {player.yellowCount}
                                                        </Badge>
                                                    </View>}
                                                    {player.redCount > 0 && <View style={grid.item}>
                                                        <Avatar.Icon style={{ backgroundColor: "white", marginHorizontal: 5 }} color="red" icon="cards" size={35} />
                                                        <Badge
                                                            style={{ ...grid.badge, backgroundColor: "green" }}
                                                        >
                                                            {player.redCount}
                                                        </Badge>
                                                    </View>}
                                                </View>
                                            </View>
                                        )
                                    })}
                                </View>
                            </View>
                        </ScrollView>
                    </TabScreen>
                </Tabs>
            </TabsProvider>
        </>
    );
}

const grid = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 300,
        padding: "3%",
        marginBottom: 5
    },
    textStyle: {
        fontSize: 25
    },
    leftColumn: {
        flex: 1,
        alignItems: 'center',
        justifyContent: "flex-start",
        flexDirection: "row",
    },
    centerColumn: {
        flex: 1,
        alignItems: 'center',
    },
    rightColumn: {
        flex: 1,
        alignItems: 'center',
        justifyContent: "flex-end",
        flexDirection: "row",
    },
    item: {
        margin: 0,
    },
    badge: {
        position: 'absolute',
        top: -2,
        right: -6,
    },
});


const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    match: state?.records?.match,
    events: state?.records?.events,
    isLoading: state?.records?.isLoading,
    squad: state?.records?.squad
});

const mapDispatchToProps = {
    getMatch,
    connectWebSocket,
    followMatch
};

export default connect(mapStateToProps, mapDispatchToProps)(Match);
