import React, { useState } from 'react';
import { View, ScrollView, Text } from 'react-native';
import { TextInput, Button, Snackbar, Chip, Divider, DataTable, IconButton } from 'react-native-paper';
import * as DocumentPicker from 'expo-document-picker';
import Papa from 'papaparse';
import { addTeam } from '../../app/store/actions/adminActions';
import { connect } from 'react-redux';
import globalStyles from '../../app/components/Styles';
import { useNavigation } from '@react-navigation/native';
import AppBar from '../../AppBar';
import ColorPicker from 'react-native-wheel-color-picker';
import { KeyboardAvoidingView } from 'react-native';
import { Platform } from 'react-native';

const CreateTeam = ({ addTeam, route }) => {

    const [name, setName] = useState('');
    const [color, setColor] = useState("#00ff00")
    const [group, setGroup] = useState('A');
    const [players, setPlayers] = useState([]);
    const [newPlayer, setNewPlayer] = useState({
        name: "",
        position: "",
        number: ""
    });
    const [file, setFile] = useState(null);
    const { tournament } = route.params;
    const navigation = useNavigation();

    const onSelectColor = (item) => {
        setColor(item)
    };

    const handleCreateTeam = async () => {
        try {
            const creation = {
                tournament: tournament._id,
                name,
                group,
                color
            }
            addTeam({ creation, players }, navigation);
        } catch (error) {
            console.log(error);
        }
    };

    const handlePlayerDelete = (idx) => {
        const newPlayers = players.filter((val, i) => i !== idx)
        setPlayers(newPlayers)
    };

    const handlePlayerAdd = () => {
        if (newPlayer.name !== "") {
            setPlayers([...players, newPlayer])
            setNewPlayer({ name: "", position: "", number: "" })
        }
    }

    const handleUploadCsv = async () => {
        try {
            const result = await DocumentPicker.getDocumentAsync({
                type: 'text/csv',
            });
            if (result.type === 'success') {
                const csv = await fetch(result.uri).then((response) => response.text());
                setFile(result.name);
                const parsed = Papa.parse(csv, { header: true });
                const playersToBe = parsed.data.filter((player) => player.name && player.position && player.number)
                console.log(playersToBe)
                setPlayers(playersToBe);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <AppBar navigation={navigation} title="Create Team" />
            <ScrollView contentContainerStyle={{ justifyContent: "center", paddingHorizontal: 20 }}>
                <KeyboardAvoidingView
                    behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
                >
                    <TextInput
                        mode="outlined"
                        style={{ marginVertical: 4 }}
                        label="Team name"
                        value={name}
                        onChangeText={setName}
                        required
                    />
                    {tournament.type === "Group-Knockout" &&
                        <TextInput
                            mode="outlined"
                            style={{ marginVertical: 4 }}
                            label="Team Group"
                            value={group}
                            onChangeText={setGroup}
                            required
                        />}
                    <View style={{ justifyContent: "center", alignItems: "center" }}>
                        <ColorPicker color={color} onColorChangeComplete={onSelectColor} thumbSize={15} sliderHidden={true} swatches={true} />
                    </View>
                    <Button style={{ marginBottom: 8 }} mode="contained" onPress={handleUploadCsv}>
                        Upload players CSV
                    </Button>
                    {file && <Chip icon="file" style={{ width: "150px" }} onClose={() => { setFile(null); setPlayers([]) }}>{file}</Chip>}
                    <Divider />
                    <Text style={{ textAlign: "center", fontWeight: "bold" }}>Check Players</Text>
                    <Divider />
                    <DataTable style={globalStyles.table}>
                        <DataTable.Header style={globalStyles.header}>
                            <DataTable.Title style={{ flex: 2, justifyContent: "center", alignItems: "center" }} numeric>Number</DataTable.Title>
                            <DataTable.Title style={{ flex: 2, justifyContent: "center", alignItems: "center" }}>Name</DataTable.Title>
                            <DataTable.Title style={{ flex: 2, justifyContent: "center", alignItems: "center" }}>Position</DataTable.Title>
                            <DataTable.Title style={{ flex: 2, justifyContent: "center", alignItems: "center" }}>Action</DataTable.Title>
                        </DataTable.Header>

                        {players?.map((player, idx) => (
                            <DataTable.Row key={idx}>
                                <View style={{ flex: 2, justifyContent: "center", alignItems: "center" }}><Text style={{ textAlign: "center" }}>{player.number}</Text></View>
                                <View style={{ flex: 2, justifyContent: "center", alignItems: "center" }}><Text style={{ textAlign: "center" }}>{player.name}</Text></View>
                                <View style={{ flex: 2, justifyContent: "center", alignItems: "center" }}><Text style={{ textAlign: "center" }}>{player.position}</Text></View>
                                <View style={{ flex: 2, justifyContent: "center", alignItems: "center" }}><IconButton icon="delete" onPress={() => handlePlayerDelete(idx)} /></View>
                            </DataTable.Row>
                        ))
                        }
                        <View style={{ flexDirection: 'row' }}>
                            <TextInput
                                mode="outlined"
                                style={{ flex: 2, width: "100%" }}
                                keyboardType="numeric"
                                value={newPlayer.number}
                                onChangeText={(text) => setNewPlayer({ ...newPlayer, number: text })}
                            />
                            <TextInput
                                mode="outlined"
                                style={{ flex: 2, width: "100%" }}
                                value={newPlayer.name}
                                onChangeText={(text) => setNewPlayer({ ...newPlayer, name: text })}
                            />
                            <TextInput
                                mode="outlined"
                                style={{ flex: 2, width: "100%" }}
                                value={newPlayer.position}
                                onChangeText={(text) => setNewPlayer({ ...newPlayer, position: text })}
                            />

                            <View style={{ flex: 2, justifyContent: "center", alignItems: "center" }}><IconButton icon="plus-circle-outline" onPress={handlePlayerAdd} /></View>
                        </View>
                    </DataTable >
                    <Button style={{ marginBottom: 8 }} mode="contained" onPress={handleCreateTeam}>
                        Create team
                    </Button>
                </KeyboardAvoidingView >
            </ScrollView >
        </>
    );
};
const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {
    addTeam
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeam);
