import React, { useEffect, useState } from "react";
import { View, ScrollView, Text, KeyboardAvoidingView, Platform } from "react-native";
import { TextInput, Button, RadioButton, ActivityIndicator, ThemeProvider, DefaultTheme, Checkbox } from "react-native-paper";
import { connect } from "react-redux";
import globalStyles from "../../app/components/Styles";
import { Divider } from '@rneui/themed';
import { addMatch } from "../../app/store/actions/adminActions";
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import { DatePickerInput, TimePickerModal } from "react-native-paper-dates";
import { getTournament } from "../../app/store/actions/userActions";
import DropDown from "react-native-paper-dropdown";
import AppBar from "../../AppBar";
import ColorPicker from "react-native-wheel-color-picker";

const CreateMatch = ({ addMatch, getTournament, isLoading, teams, route, defaults, fullTournament }) => {
    const navigation = useNavigation();
    const { tournament, match } = route.params;
    
    const [stage, setStage] = useState(fullTournament?.type === "Knockout" ? "Knockout Match" : (fullTournament?.type === "League" ? "Group Match" : undefined));
    const [round, setRound] = useState(undefined);
    const [teamA, setTeamA] = useState(match ? match.teamA._id : "");
    const [teamAColor, setTeamAColor] = useState(match ? match.teamA.color : "")
    const [teamB, setTeamB] = useState(match ? match.teamB._id : "");
    const [teamBColor, setTeamBColor] = useState(match ? match.teamB.color : "")
    const [shirtColors, setShirtColors] = useState(false)
    const [date, setDate] = useState(defaults?.date ? new Date(defaults?.date) : new Date());
    const [livestream, setLivestream] = useState("");
    const [time, setTime] = useState({ hours: 18, minutes: 0 });
    const [duration, setDuration] = useState(defaults?.duration);
    const [visible, setVisible] = React.useState(false);
    const [showDropDown, setShowDropDown] = useState(false);
    const [showDropDown2, setShowDropDown2] = useState(false);
    const [showDropDown3, setShowDropDown3] = useState(false);
    const [showDropDown4, setShowDropDown4] = useState(false);

    const onDismiss = React.useCallback(() => {
        setVisible(false)
    }, [setVisible])

    const onConfirm = React.useCallback(
        ({ hours, minutes }) => {
            setVisible(false);
            setTime({ hours, minutes });
        },
        [setVisible]
    );

    useEffect(() => {
        getTournament({ tournamentId: (match ? tournament : tournament._id) });
        console.log(match)
    }, [])

    const handleCreateMatch = () => {
        const creation = {
            date: moment(date).startOf("day").add(time.hours, "hours").add(time.minutes, "minutes").format("MM-DD-YYYY HH:mm"),
            stage,
            round,
            teamA: (match ? match.teamA._id : teamA),
            teamB: (match ? match.teamB._id : teamB),
            teamAColor: shirtColors ? teamAColor : undefined,
            teamBColor: shirtColors ? teamBColor : undefined,
            tournament: (match ? tournament._id : tournament._id),
            duration,
            livestream
        }
        console.log(creation)
        addMatch({ creation }, navigation)
    };

    const onSelectColorA = (item) => {
        setTeamAColor(item)
    };

    const onSelectColorB = (item) => {
        setTeamBColor(item)
    };

    if (isLoading) {
        return (
            <View style={globalStyles.container}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <ScrollView>
            <AppBar navigation={navigation} title={fullTournament?.name} />
            <View style={{ padding: 30 }} contentContainerStyle={{ justifyContent: "center" }}>
                {!match ? (<>
                    <DropDown
                        dropDownItemTextStyle={{ color: "whitesmoke" }}
                        dropDownItemSelectedTextStyle={{ fontWeight: "bold", color: "white" }}
                        label={"Team A"}
                        mode={"outlined"}
                        visible={showDropDown}
                        showDropDown={() => setShowDropDown(true)}
                        onDismiss={() => setShowDropDown(false)}
                        value={teamA}
                        setValue={setTeamA}
                        list={teams?.filter((team) => team._id !== teamB).map((team) => {
                            return {
                                label: team.name,
                                value: team._id
                            }
                        })}
                    />
                    {shirtColors && <View style={{ justifyContent: "center", alignItems: "center" }}>
                        <ColorPicker color={teamAColor} onColorChangeComplete={onSelectColorA} thumbSize={15} sliderHidden={true} swatches={true} />
                    </View>}
                    <DropDown
                        dropDownItemTextStyle={{ color: "whitesmoke" }}
                        dropDownItemSelectedTextStyle={{ fontWeight: "bold", color: "white" }}
                        label={"Team B"}
                        mode={"outlined"}
                        visible={showDropDown2}
                        showDropDown={() => setShowDropDown2(true)}
                        onDismiss={() => setShowDropDown2(false)}
                        value={teamB}
                        setValue={setTeamB}
                        list={teams?.filter((team) => team._id !== teamA).map((team) => {
                            return {
                                label: team.name,
                                value: team._id
                            }
                        })}
                    />
                    {shirtColors && <View style={{ justifyContent: "center", alignItems: "center" }}>
                        <ColorPicker color={teamBColor} onColorChangeComplete={onSelectColorB} thumbSize={15} sliderHidden={true} swatches={true} />
                    </View>}
                    <Divider style={{marginTop: 5}} />
                    <Checkbox.Item label="Set Shirt Colors" onPress={() => { setShirtColors(!shirtColors); }} status={shirtColors ? "checked" : "unchecked"} />  
                    <Divider style={{marginBottom: 5}} />
                    {fullTournament?.type === "Group-Knockout" && <DropDown
                        dropDownItemTextStyle={{ color: "whitesmoke" }}
                        dropDownItemSelectedTextStyle={{ fontWeight: "bold", color: "white" }}
                        style={globalStyles.input}
                        label={"Match Stage"}
                        mode={"outlined"}
                        visible={showDropDown3}
                        showDropDown={() => setShowDropDown3(true)}
                        onDismiss={() => setShowDropDown3(false)}
                        value={stage}
                        setValue={setStage}
                        list={[
                            { label: "Group", value: "Group Match" },
                            { label: "Knockout", value: "Knockout Match" },
                        ]}
                    />}
                    {stage === "Knockout Match" && <DropDown
                        dropDownItemTextStyle={{ color: "whitesmoke" }}
                        dropDownItemSelectedTextStyle={{ fontWeight: "bold", color: "white" }}
                        style={globalStyles.input}
                        label={"Knockout Stage"}
                        mode={"outlined"}
                        visible={showDropDown4}
                        showDropDown={() => setShowDropDown4(true)}
                        onDismiss={() => setShowDropDown4(false)}
                        value={round}
                        setValue={setRound}
                        list={[
                            { label: "Round of 64", value: "Round of 64" },
                            { label: "Round of 32", value: "Round of 32" },
                            { label: "Round of 16", value: "Round of 16" },
                            { label: "Quarter Final", value: "Quarter Final" },
                            { label: "Semi Final", value: "Semi Final" },
                            { label: "Final", value: "Final" },
                        ]}
                    />}</>) : (<>
                        <Text style={{ fontSize: 25, fontWeight: "bold", textAlign: "center", marginBottom: 20 }}>{match.teamA.name} VS {match.teamB.name}</Text>
                    </>)}
                <ThemeProvider theme={{
                    ...DefaultTheme,
                    colors: {
                        ...DefaultTheme.colors,
                        primary: '#000B26',
                        onPrimary: "#ffffff",
                        surface: '#ffffff',
                    }
                }}>
                    <DatePickerInput
                        locale="en-GB"
                        mode="outlined"
                        style={{ marginTop: 8 }}
                        label="Date"
                        value={date}
                        onChange={(d) => setDate(d)}
                        inputMode="start"
                    />
                </ThemeProvider>

                <Button style={{ marginVertical: 8 }} onPress={() => setVisible(true)} uppercase={false} mode="outlined">
                    Pick time
                </Button>

                <ThemeProvider theme={{
                    ...DefaultTheme,
                    colors: {
                        ...DefaultTheme.colors,
                        primary: '#ffffff',
                        onPrimary: "#000B26",
                        onBackground: '#000B26',
                        secondaryContainer: '#ffffff',
                        onSecondary: "#000B26",
                        surface: '#000B26',
                        onSurfaceVariant: "#ffffff"
                    }
                }}>
                    <TimePickerModal
                        locale="en-GB"
                        visible={visible}
                        onDismiss={onDismiss}
                        onConfirm={onConfirm}
                        hours={time.hours}
                        minutes={time.minutes}
                    />
                </ThemeProvider>

                <TextInput
                    mode="outlined"
                    label="Duration*"
                    value={duration}
                    keyboardType="numeric"
                    onChangeText={(text) => setDuration(text)}
                />
                <TextInput
                    mode="outlined"
                    label="Livestream URL"
                    value={livestream}
                    onChangeText={(text) => setLivestream(text)}
                />
                <Button style={{ marginTop: 8 }} mode="contained" onPress={handleCreateMatch}>
                    Create Match
                </Button>
            </View>
        </ScrollView>
    );
}
const mapStateToProps = (state) => ({
    isLoading: state?.records?.isLoading,
    teams: state?.records?.teams,
    defaults: state?.records?.tournament?.matchDefaults,
    fullTournament: state?.records?.tournament
});

const mapDispatchToProps = {
    addMatch,
    getTournament
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateMatch);